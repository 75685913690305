export default {
  state: () => ({
    testKey: "test core value",
    requestCache: {},
    requestsInProgress: {},
    lastPurged : new Date().getTime(),

  }),
  mutations: {
    generic(state, payload) {
      if (typeof payload !== "object") {
        return false;
      }

      if (typeof payload.key !== "string") {
        return false;
      }

      if (typeof payload.value === "undefined") {
        return false;
      }

      state[payload.key] = payload.value;

      return true;
    },
    setToCache(state, payload) {
      let now = new Date().getTime();

      if (state.lastPurged + config.asyncData.cache.minimalPurgeInterval <= now) {
        this.commit('asyncData/purgeExpiredRequestDataCache');
      }
      if (typeof payload !== "object" || !payload.key) {
        warn('store-asyncData/setToCache - payload must be an object with a "key" key');
        return false;
      }

      let key = payload.key;
      let ttlMs = payload.ttlMs
        ? payload.ttlMs
        : config.asyncData.cache.defaultCacheTtlMs;
      let data = payload.data;
      state.requestCache[key] = {
        ttlMs,
        data,
        created: new Date().getTime(),
        validUntilMs: new Date().getTime() + ttlMs,
      };

      return false;
    },
    clearRequestDataFromCache(state, payload) {
      try {
        if (state.requestCache[payload]) {
          delete state.requestCache[payload];
          return true;
        }

        return false;
      } catch (e) {
        return false;
      }
    },
    clearRequestDataCache(state, payload) {
      state.requestCache = {};
      return true;
    },
    setRequestAsInProgress(state, payload) {
      if (typeof payload !== "object" || !payload.key || !payload.promise) {
        warn(
          'store-asyncData/setAsInProgress - payload must be an object with a "key" and "promise" keys'
        );
        return false;
      }

      state.requestsInProgress[payload.key] = payload;
      return true;
    },
    setRequestAsComplete(state, payload) {
      if (state.requestsInProgress[payload]) {
        delete state.requestsInProgress[payload];
      }

      return true;
    },
    purgeExpiredRequestDataCache(state) {
      let keys = Object.keys(state.requestCache);
      let now = new Date().getTime();
      keys.forEach(key => {
        let stored = state.requestCache[key];


        if (stored.validUntilMs < now) {
          delete state.requestCache[key];
        }
      });

      state.lastPurged = now;
      return true;
    }
  },
  actions: {},
  getters: {
    generic: (state) => (key) => {
      if (typeof state[key] === "undefined") {
        return undefined;
      } else {
        return state[key];
      }
    },
    all(state) {
      return state;
    },
    getFromCache: (state) => (key) => {
      if (!state.requestCache[key]) {
        return undefined;
      }

      let stored = state.requestCache[key];
      let now = new Date().getTime();

      if (stored.validUntilMs < now) {
        return undefined;
      }

      return stored.data;
    },
    isInProgress: (state) => (key) => {
      if (!state.requestsInProgress[key]) {
        return false;
      }

      return true;
    },
    requestInProgress: (state) => (key) => {
      if (!state.requestsInProgress[key]) {
        return undefined;
      }

      return state.requestsInProgress[key];
    },
  },

  // TODO: with strict mode, this bugs because async comopnent data is stored here, which component may modify.
};
